import { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { format } from "date-fns";
import { google, CalendarEvent } from "calendar-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
import "./Home.css";
import vidHeaderDesktop from "./header.mp4";
import vidHeaderMobile from "./header-mobile.mp4";
export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}
interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const Wrapper = styled("div")({
  // fontFamily: `${process.env.REACT_APP_PROJECT_FONT}`,
  fontFamily: "arial",
  minHeight: "100vh",
  backgroundColor: `${process.env.REACT_APP_PROJECT_BACKGROUND_COLOR}`,
});
const HeaderVideoDesktop = styled("video")({
  width: "100%",
  "@media (max-width: 760px)": {
    display: "none",
  },
});
const HeaderVideoMobile = styled("video")({
  width: "100%",
  "@media (min-width: 761px)": {
    display: "none",
  },
});
const Container = styled("div")({
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});
const Subtitle = styled("h2")({
  fontSize: "1.3rem",
  fontWeight: "normal",
  paddingBottom: "0px",
  marginTop: "0px",
});
const CalendarLink = styled("a")({
  textDecoration: "none",
  transition: "all 0.2s ease-in-out",
  borderBottom: "2px solid transparent",
  color: `${process.env.REACT_APP_PROJECT_COLOR}`,
  "&:hover": {
    borderColor: "inherit",
  },
});
const CounterInfo = styled("h3")({
  fontSize: "1rem",
  fontWeight: "normal",
  paddingBottom: "10px",
  marginTop: "0px",
});
const BlockchainInfo = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gridGap: "10px",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  paddingBottom: 30,
  "& > div": {
    border: "1px solid white",
    height: "100%",
    borderRadius: "3px",
    padding: "10px",
    textTransform: "uppercase",
    minHeight: 90,
    flex: "1 1 auto",
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
const ConnectButton = styled(WalletDialogButton)({
  letterSpacing: "2px",
  borderRadius: "50px",
  padding: "8px 25px",
  fontSize: "1.3rem",
});
const CounterText = styled("span")({});
const MintButton = styled(Button)({
  letterSpacing: "2px",
  borderRadius: "50px",
  padding: "8px 25px",
  fontSize: "1.3rem",
});
const Footer = styled("div")({
  fontSize: "1rem",
  fontWeight: "normal",
  paddingTop: "20px",
});
const SocialLinks = styled("div")({
  display: "flex",
  gridGap: 20,
  paddingTop: 20,
  justifyContent: "center",
  alignItems: "center",
});
const FooterLink = styled("a")({
  textDecoration: "none",
  transition: "all 0.2s ease-in-out",
  borderBottom: "2px solid transparent",
  color: `${process.env.REACT_APP_PROJECT_COLOR}`,
  "&:hover": {
    borderColor: "inherit",
  },
});
const DevTools = styled("div")({
  position: "absolute",
  bottom: 120,
  display: "flex",
  gridGap: 10,
});

const renderCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} HOURS, {minutes} MINUTES, {seconds} SECONDS
    </CounterText>
  );
};

console.log("TO VIEW DEBUG OPTIONS:");
console.log('localStorage.setItem("TEST_STATES", "true")');
console.log("process.env", process.env);

export default function Home(props: HomeProps) {
  const wallet = useAnchorWallet();
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState<boolean>(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState<boolean>(false); // true when user got to press MINT
  const [itemsAvailable, setAvailable] = useState<number>();
  const [itemsRedeemed, setRedeemed] = useState<number>();
  const [itemsRemaining, setRemaining] = useState<number>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [startDate, setStartDate] = useState<number>(
    new Date(props.startDate).getTime()
  );
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }
      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;

      const candyMachineState = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      console.log("candyMachineState", candyMachineState);
      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRedeemed,
        itemsRemaining,
      } = candyMachineState;

      setIsSoldOut(itemsRemaining === 0);
      const goLiveDateTimestamp = goLiveDate.getTime();
      console.log(
        "goLiveDateTimestamp",
        goLiveDateTimestamp,
        new Date(goLiveDateTimestamp)
      );
      setStartDate(goLiveDateTimestamp);
      setCandyMachine(candyMachine);
      setAvailable(itemsAvailable);
      setRedeemed(itemsRedeemed);
      setRemaining(itemsRemaining);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  const subtitleText = useMemo(() => {
    const timestamp: number | undefined = startDate ? startDate : 0;
    const localDateTime: Date = new Date(timestamp);
    const localDateTimeString: string = format(
      localDateTime,
      "MMM do, hh:mmaa OOO"
    ).toUpperCase();

    const calendarEvent: CalendarEvent = {
      title: `${process.env.REACT_APP_PROJECT_NAME} MINT`,
      description: "Be there!",
      start: localDateTime,
      duration: [1, "hour"],
    };
    const calendarLink = google(calendarEvent);
    return (
      <CalendarLink
        href={calendarLink}
        download="Mint Reminder"
        target="_blank"
        rel="noopener noreferrer"
      >
        {localDateTimeString}
      </CalendarLink>
    );
  }, [startDate]);

  const counterText = useMemo(() => {
    if (isSoldOut) return "WE ARE SOLD OUT";
    if (!isActive)
      return (
        <Countdown
          date={startDate}
          onMount={({ completed }) => {
            completed && setIsActive(true);
          }}
          onComplete={() => {
            setIsActive(true);
          }}
          renderer={renderCounter}
        />
      );
    return "MINTING IS LIVE!";
  }, [isActive, isSoldOut, startDate]);

  const blockchainInfo = useMemo(() => {
    if (!!wallet) {
      return (
        <BlockchainInfo>
          <div>
            <div>
              Wallet: {shortenAddress(wallet.publicKey.toBase58() || "")}
            </div>
            <div>Balance: {(balance || 0).toLocaleString()} SOL</div>
          </div>
          <div>
            <div>Total Available: {itemsAvailable}</div>
            <div>Redeemed: {itemsRedeemed}</div>
            <div>Remaining: {itemsRemaining}</div>
          </div>
        </BlockchainInfo>
      );
    }
    return null;
  }, [balance, itemsAvailable, itemsRedeemed, itemsRemaining, wallet]);

  const renderMintButtonContent = useCallback(() => {
    if (isMinting) return <CircularProgress size={25} />;
    if (isSoldOut) return "SOLD OUT";
    return "MINT";
  }, [isMinting, isSoldOut]);

  return (
    <Wrapper>
      <HeaderVideoDesktop src={vidHeaderDesktop} autoPlay muted loop />
      <HeaderVideoMobile src={vidHeaderMobile} autoPlay muted loop />

      <Container>
        {/* <Subtitle>{subtitleText}</Subtitle> */}
        <CounterInfo>{counterText}</CounterInfo>
        {blockchainInfo}

        {!!wallet ? (
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
            color="primary"
          >
            {renderMintButtonContent()}
          </MintButton>
        ) : (
          <ConnectButton>Connect Wallet</ConnectButton>
        )}

        <Footer>
          <div>
            {process.env.REACT_APP_PROJECT_QUANTITY} NFTs,{" "}
            {process.env.REACT_APP_PROJECT_PRICE} SOL EACH.
          </div>
          <SocialLinks>
            <FooterLink
              href={process.env.REACT_APP_PROJECT_TWITTER}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </FooterLink>
            <FooterLink
              href={process.env.REACT_APP_PROJECT_DISCORD}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} size="lg" />
            </FooterLink>
            <FooterLink
              href={process.env.REACT_APP_PROJECT_WEBSITE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGlobe} size="lg" />
            </FooterLink>
          </SocialLinks>
        </Footer>
      </Container>

      {localStorage?.getItem("TEST_STATES") === "true" && (
        <DevTools>
          <br />
          <button onClick={() => setIsActive((state) => !state)}>
            setIsActive {isActive.toString()}
          </button>
          <button onClick={() => setIsSoldOut((state) => !state)}>
            setIsSoldOut {isSoldOut.toString()}
          </button>
          <button onClick={() => setIsMinting((state) => !state)}>
            setIsMinting {isMinting.toString()}
          </button>
          <button
            onClick={() => {
              setIsActive(false);
              setStartDate(Date.now() + 3000);
            }}
          >
            setStartDate
          </button>
        </DevTools>
      )}

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
}
